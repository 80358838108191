/*.App {
  text-align: center;
}*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*Fonts*/
@font-face {
  font-family: 'helveticaneuecyrblack';
  src: url('./assets/fonts/helveticaneuecyr_black.woff2') format('woff2'),
       url('./assets/fonts/helveticaneuecyr_black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;    
}
@font-face {
  font-family: 'helveticaneuecyrroman';
  src: url('./assets/fonts/helveticaneuecyr_roman.woff2') format('woff2'),
       url('./assets/fonts/helveticaneuecyr_roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;   
}
body{
  font-family: 'helveticaneuecyrroman';
  font-weight: normal;
}
.container-fluid{
  max-width: 1033px;
  justify-content: center;
  margin: 0 auto 50px;
}

/*style header*/
.header-title{  
  font-family: 'helveticaneuecyrblack'; 
  font-weight: 900;
  font-size: 45px;
  line-height: 54px;
  letter-spacing: 0.9px;
  color: #7db360;
  margin-bottom: 0;
}
/*End style header*/

/*style table*/
.table td{
  border: none;
}
.table-bordered{
  border: none;
}
/*End style table*/

/*TableData*/
.table-data{
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: #2c2a29;
}

/*style RationOne*/
/*title*/
.menu-recomendation__haed_data{
  justify-content: flex-end;
}
.menu-recomendation__haed_title{
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #2c2a29;
}
.menu-recomendation__haed_title span{
  color: #e04e39;
}
.menu-recomendation__haed_data-item{
  border: none;
  padding: 0 0 0 1rem;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
}
.menu-recomendation__haed_data-item + .menu-recomendation__haed_data-item::before{
  content: "|";
  color: #c9c9c9;
  padding-right: 1rem;
}
.menu-recomendation__haed_data-text{
  text-align: right;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  text-align: right;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 0.6;
}
/*progress*/
.menu_data-title{
  font-family: 'helveticaneuecyrblack';
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: .4px;
    margin-bottom: 3px;
}
.menu_data-text{
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: .4px;
    color: #707070;
    margin-bottom: 20px;
}
.progress-bar span{
    font-weight: 500;
    font-size: 10px;
    line-height: 23px;
    letter-spacing: .4px;
    color: #707070;
}
.progress{
  background-color: #fff;
  margin-bottom: 4px;
}
.progress-bar{
  border-radius: 6px;
}
/*table*/
.ration-table-one{
    font-weight: 500;
    font-size: 10px;
    line-height: 23px;
    letter-spacing: .4px;
    color: #707070;
    vertical-align: middle;
    width: 100%;
}
.ration-table-one td span{
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #2c2a29;
}

/*RationTableDesc*/
.ration-table-desc-text{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #2c2a29;
}

/*RationRecept*/
.recept-title{
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #2c2a29;
    margin-bottom: 16px;
}
.recept-subtitle{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #2c2a29;
}
.recept-title-menu{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #e04e39;
    margin-bottom: 16px;
}
.recept-text-menu{
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #2c2a29;
    margin-bottom: 16px;
}
.recept-description-menu{
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #2c2a29;
    margin-bottom: 16px;
}
/*End style RationOne*/

/*RationLinks*/
.recept-btn{
    max-width: 475px;
    width: 100%;
}
.recept-btn a{
    max-width: 475px;
    width: 100%;
    border-radius: 60px;
    font-weight: 500;
    font-size: 30px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #ffffff;
    padding: 26px;
    cursor: pointer;
    border: none;
    background-color: #7db360;
}
.recept-btn a:hover{
  color: #61dafb;
  text-decoration: none;
  background-color: #399de0;
}
/*End RationLinks*/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
